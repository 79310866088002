<template>
  <div>
    <ed-convenio :intConvenioId="$route.params.intId" :key="$root.$session.versao" />
  </div>
</template>

<script>
import EdConvenio from "@/components/cadastro/convenio/editar";

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: { EdConvenio },
  mounted() {
    if (!this.$route.params.intId) {
      this.$router.push({ name: "cadastro.convenio.register" });
    }
  },
  created() {},
  data() {
    return {};
  },
  methods: {},
};
</script>
