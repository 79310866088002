<template>
  <div>
    <v-tabs v-model="strTab" centered icons-and-text>
      <v-tab href="#tab-cadastro">
        Informações do Convênio
        <i :class="$utilidade.getIcone('cadastroGeral') + ' fa-2x'"></i>
      </v-tab>
      <v-tab href="#tab-plano">
        Planos
        <i :class="$utilidade.getIcone('convenio') + ' fa-2x'"></i>
      </v-tab>
    </v-tabs>

    <ed-form
      :formData="formData"
      :handlerSave="salvar"
      v-if="!this.$root.$session.loadingContent"
    >
      <cadastro-geral :formData="formData" v-show="strTab == 'tab-cadastro'" />
      <cadastro-plano :formData="formData" v-show="strTab == 'tab-plano'" />
    </ed-form>
  </div>
</template>

<script>
import CadastroGeral from "./partials/geral";
import CadastroPlano from "./partials/plano";

import { EdForm } from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    intConvenioId: {
      type: [Number, String],
      required: true,
      default: null,
    },
  },
  components: {
    EdForm,
    CadastroGeral,
    CadastroPlano,
  },
  provide() {
    return {};
  },
  mounted() {
    this.getConvenio();
  },
  created() {},
  data() {
    return {
      strTab: "tab-cadastro",
      formData: {
        intId: 0,
        strNome: null,
        planos: [],
      },
    };
  },
  watch: {
    intConvenioId() {
      this.getConvenio();
    },
  },
  methods: {
    getConvenio() {
      if (!this.intConvenioId) return;

      this.$root.$dialog.loading(true);

      this.$root.$request
        .get("Cadastro/Convenio", { intId: this.intConvenioId })
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200) {
            let formData = Object.assign(objResponse.result, {});
            this.formData = formData;
          }
        });
    },

    salvar() {
      this.$root.$request.post("Cadastro/Convenio", this.formData).then((objResponse) => {
        if (objResponse.status == 200) {
          let strRota = this.$route.name;

          if (strRota.indexOf(".register") != -1) {
            this.$router.push({
              name: strRota.replace(".register", ".edit"),
              params: { intId: objResponse.result.intId },
            });
          } else {
            this.getConvenio();
          }
        }
      });
    },
  },
};
</script>
