<template>
  <div class="row mb-3">
    <ed-modal v-model="boolExibirCadastroPlano" width="50%">
      <div slot="title">
        {{ formDataPlano.intId ? "Editar Plano" : "Inserir novo Plano" }}
      </div>

      <div class="" slot="content">
        <ed-form :formData="formDataPlano" :handlerSave="salvar" disableFixedSave>
          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            v-model="formDataPlano.strNome"
            name="strNome"
            label="Nome do Plano"
            required
            rules="required"
          />
        </ed-form>
      </div>
    </ed-modal>

    <ed-table
    :key="$root.$session.versao"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :loading="loading"
      @register="onRegister"
      @edit="onEdit"
      @delete="onDelete"
      :config="{ registerName: 'Inserir novo plano' }"
      disabledSearchRows
    />
  </div>
</template>

<script>
import {
  EdForm,
  EdTable,
  EdModal,
  EdButton,
  EdInputText,
  EdInputTipo,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
  },
  components: {
    EdForm,
    EdTable,
    EdModal,
    EdButton,
    EdInputText,
    EdInputTipo,
  },
  provide() {
    return {};
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      loading: false,
      boolExibirCadastroPlano: false,
      arrayHeaders: [
        {
          text: "Plano",
          sortable: true,
          value: "strNome",
        },
      ],
      arrayRows: [],
      formDataPlano: {
        intId: 0,
        strNome: null,
      },
    };
  },
  watch: {
    formData: {
      handler: function (newVal) {
        this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    initialize() {
      if (!this.formData.planos) {
        this.formData.planos = [];
      }

      let arrayRows = [];
      this.formData.planos.forEach((objConvenioPlano) => {
        let item = {
          intId: objConvenioPlano.intId,
          strNome: objConvenioPlano.strNome,
          _item: objConvenioPlano,
        };

        arrayRows.push(item);
      });

      this.arrayRows = arrayRows;
    },

    onRegister() {
      this.boolExibirCadastroPlano = !this.boolExibirCadastroPlano;

      this.formDataPlano = {
        intId: 0,
        strNome: null,
      };
    },

    onEdit(data) {
      this.formDataPlano = Object.assign(data, {});
      this.boolExibirCadastroPlano = !this.boolExibirCadastroPlano;
    },

    onDelete(data) {
      let self = this;

      data.forEach((intId) => {
        let indexConvenioPlano = self.getIndexByIntId(intId);

        if (indexConvenioPlano != -1) {
          self.formData.planos.splice(indexConvenioPlano, 1);
        }
      });
    },

    getIndexByIntId(intId) {
      return this.formData.planos.findIndex((c) => c.intId == intId);
    },

    salvar() {
      let intIndexConvenioPlano = this.getIndexByIntId(this.formDataPlano.intId);

      if (intIndexConvenioPlano != -1) {
        this.formData.planos[intIndexConvenioPlano] = Object.assign(
          this.formDataPlano,
          {}
        );
      } else {
        let objUltimoPlano = null;

        if (this.formData.planos.length) {
          objUltimoPlano = this.formData.planos[this.formData.planos.length - 1];
        }

        this.formData.planos.push({
          intId: objUltimoPlano ? objUltimoPlano.intId + 1 : 1,
          strNome: this.formDataPlano.strNome,
        });
      }

      this.boolExibirCadastroPlano = !this.boolExibirCadastroPlano;
    },
  },
};
</script>
