<template>
  <div class="row ma-0 pa-0">
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
      <span class="form-title">Dados cadastrais</span>

      <div class="row">
        <ed-input-text
          class="col-xs-12 col-sm-12 col-md-12 col-lg-812 col-xl-12"
          v-model="formData.strNome"
          name="strNome"
          label="Nome"
          required
          rules="required"
        />

        <ed-input-text-area
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          v-model="formData.strObservacao"
          name="strObservacao"
          label="Observações"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { EdInputText, EdInputTextArea } from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
  },
  components: {
    EdInputText,
    EdInputTextArea,
  },
  inject: [],
  provide() {
    return {};
  },
  mounted() {},
  created() {},
  data() {
    return {};
  },
  watch: {},
  methods: {
    setCnae(item) {
      if (item?.strCodigo) {
        this.formData.intGrauRisco = item.intGrauRisco;
      }
    },
  },
};
</script>
